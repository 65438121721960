.Header {
    display: flex;
    width: 100%;
    height: fit-content;
    padding: 5px;
    /* justify-content: space-between; */
  }
  
  .Header h1 {
    margin: 1rem;
    font-size: 2rem;
  }
  
  .Header img{
    height: 50px;
    width: 50px;
    margin: 1rem 0rem 1rem 2rem;
    /* margin-left: 2rem; */
  }
  a{
    text-decoration: none;
    color: white;
  }