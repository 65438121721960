body {
  margin: 0;
  padding: 0;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Header {
    display: flex;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 5px;
    /* justify-content: space-between; */
  }
  
  .Header h1 {
    margin: 1rem;
    font-size: 2rem;
  }
  
  .Header img{
    height: 50px;
    width: 50px;
    margin: 1rem 0rem 1rem 2rem;
    /* margin-left: 2rem; */
  }
  a{
    text-decoration: none;
    color: white;
  }
.App {
  text-align: center;
  background-color: black;
  color: white;
}
#Home {
    display: block;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 5px;
    /* justify-content: space-between; */
}

/* desktop screen for tech and business solution banner */
.tech {
    display: flex;
    justify-content: space-around;
    margin: 4rem 1rem;
}

.tech div {
    flex: 1 1;
}

.tech h2 {
    font-size: 3rem;
    font-weight: bolder;
    margin: 2rem 0;
}

.subApps {
    display: block;
    margin: 1rem;
    font-size: 2rem;
    font-weight: 600;
    background-color: blueviolet;
    background-image: linear-gradient(to right, rgb(134, 0, 134), rgb(233, 80, 113));
    color: black;
    padding: 1rem 2rem;
    border-radius: 2rem;
}

.subApps:hover {
    color: bisque;
    cursor: pointer;
}

.tech img {
    border-radius: 2rem;
    box-shadow: 0 0 10rem blue;
}

/* desktop screen for about us banner */
.AboutUs {
    margin: 2rem 3rem;
    padding: 2rem 3rem;
}

.AboutUs h2 {
    font-size: 4rem;
    padding: 1rem;
    margin: 1rem;
}

.AboutUs a {
    color: blueviolet;
}

.AboutUs a:hover {
    color: rgb(192, 169, 255);
}

.AboutUs p {
    font-size: 1.5rem;
    font-weight: 500;
}

/* css for mobile screen */
@media screen and (max-width: 950px) {

    /* mobile screen for tech and business solution banner */
    .tech {
        flex-direction: column-reverse;
        padding: 5rem 1rem;
        margin: 1rem;
        background-image: url('/Images/tech.PNG');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        box-shadow: 0 0 10rem blue;
        border-radius: 3rem;
    }

    .tech h2 {
        font-size: 3rem;
        font-weight: bolder;
        color: black;
        margin: 2rem;
    }

    .tech img {
        display: none;
    }

    /* mobile screen for about us banner */
    .AboutUs {
        padding: 2rem 3rem;
        margin: 0;
    }

    .AboutUs h2 {
        font-size: 2rem;
        padding: 1rem;
    }

    .AboutUs p {
        font-size: 1rem;
        font-weight: 400;
    }

}
.banner {
    /* display: flex; */
    justify-content: space-around;
    /* flex-direction: row-reverse; */
    margin: 5rem 1rem;
}

.bannerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.banner h2 {
    font-size: 3rem;
    font-weight: bolder;
    margin: 1rem;
    color: blueviolet;
}

.banner p {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 5px;
}

.banner div {
    width: 50%;
    padding: 1rem;
}

.banner img {
    border-radius: 2rem;
}

/* css for mobile screen */
@media screen and (max-width: 950px) {
    .banner {
        margin: 2rem 1rem;
    }

    .banner h2 {
        font-size: 150%;
        font-weight: bolder;
    }

    .banner p {
        width: 80%;
        font-size: 100%;
        font-weight: 400;
    }


    .banner img {
        /* height: 80%; */
        width: 90%;
        /* display: none; */
    }

}

@media screen and (max-width: 750px) {
    .banner {
        margin: 1rem;
    }

    .banner h2 {
        font-size: 120%;
        margin: 0;
    }

    .banner p {
        font-size: 70%;
        font-weight: 300;
        margin: 5px;
    }
}
.footer {
    display: flex;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 5px;
    justify-content: space-around;
    /* background-color: rgb(26, 26, 26); */
}

.footer a {
    display: flex;
    margin: 1rem;
}

.footer h1 {
    font-size: 2rem;
}

.footer h2 {
    font-size: 3rem;
    color: blueviolet;
    margin: 1rem;
}

.footer img {
    height: 4rem;
    width: 4rem;
    margin: 1rem;
}

.footer p {
    font-size: larger;
}

.socialMedia {
    display: flex;
    justify-content: center;
}

.socialMedia a {
    margin: 0;
}

.socialMedia img {
    margin: 0;
    width: 80%;
    height: 80%;
}


@media screen and (max-width: 950px) {
    .footer {
        flex-direction: column;
    }

    .footer h1 {
        margin: 0;
        font-size: 2rem;
    }

    .footer img {
        height: 3rem;
        width: 3rem;
        margin: 0rem;
        padding-right: 1rem;
    }

    .footer a {
        /* justify-content: center; */
        margin: 0rem;
    }

    .connect {
        margin-left: 1rem;
    }

    .footer h2 {
        font-size: 2rem;
        margin: 0rem;
    }
}
