.footer {
    display: flex;
    width: 100%;
    height: fit-content;
    padding: 5px;
    justify-content: space-around;
    /* background-color: rgb(26, 26, 26); */
}

.footer a {
    display: flex;
    margin: 1rem;
}

.footer h1 {
    font-size: 2rem;
}

.footer h2 {
    font-size: 3rem;
    color: blueviolet;
    margin: 1rem;
}

.footer img {
    height: 4rem;
    width: 4rem;
    margin: 1rem;
}

.footer p {
    font-size: larger;
}

.socialMedia {
    display: flex;
    justify-content: center;
}

.socialMedia a {
    margin: 0;
}

.socialMedia img {
    margin: 0;
    width: 80%;
    height: 80%;
}


@media screen and (max-width: 950px) {
    .footer {
        flex-direction: column;
    }

    .footer h1 {
        margin: 0;
        font-size: 2rem;
    }

    .footer img {
        height: 3rem;
        width: 3rem;
        margin: 0rem;
        padding-right: 1rem;
    }

    .footer a {
        /* justify-content: center; */
        margin: 0rem;
    }

    .connect {
        margin-left: 1rem;
    }

    .footer h2 {
        font-size: 2rem;
        margin: 0rem;
    }
}