#Home {
    display: block;
    width: 100%;
    height: fit-content;
    padding: 5px;
    /* justify-content: space-between; */
}

/* desktop screen for tech and business solution banner */
.tech {
    display: flex;
    justify-content: space-around;
    margin: 4rem 1rem;
}

.tech div {
    flex: 1;
}

.tech h2 {
    font-size: 3rem;
    font-weight: bolder;
    margin: 2rem 0;
}

.subApps {
    display: block;
    margin: 1rem;
    font-size: 2rem;
    font-weight: 600;
    background-color: blueviolet;
    background-image: linear-gradient(to right, rgb(134, 0, 134), rgb(233, 80, 113));
    color: black;
    padding: 1rem 2rem;
    border-radius: 2rem;
}

.subApps:hover {
    color: bisque;
    cursor: pointer;
}

.tech img {
    border-radius: 2rem;
    box-shadow: 0 0 10rem blue;
}

/* desktop screen for about us banner */
.AboutUs {
    margin: 2rem 3rem;
    padding: 2rem 3rem;
}

.AboutUs h2 {
    font-size: 4rem;
    padding: 1rem;
    margin: 1rem;
}

.AboutUs a {
    color: blueviolet;
}

.AboutUs a:hover {
    color: rgb(192, 169, 255);
}

.AboutUs p {
    font-size: 1.5rem;
    font-weight: 500;
}

/* css for mobile screen */
@media screen and (max-width: 950px) {

    /* mobile screen for tech and business solution banner */
    .tech {
        flex-direction: column-reverse;
        padding: 5rem 1rem;
        margin: 1rem;
        background-image: url('/Images/tech.PNG');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        box-shadow: 0 0 10rem blue;
        border-radius: 3rem;
    }

    .tech h2 {
        font-size: 3rem;
        font-weight: bolder;
        color: black;
        margin: 2rem;
    }

    .tech img {
        display: none;
    }

    /* mobile screen for about us banner */
    .AboutUs {
        padding: 2rem 3rem;
        margin: 0;
    }

    .AboutUs h2 {
        font-size: 2rem;
        padding: 1rem;
    }

    .AboutUs p {
        font-size: 1rem;
        font-weight: 400;
    }

}