.banner {
    /* display: flex; */
    justify-content: space-around;
    /* flex-direction: row-reverse; */
    margin: 5rem 1rem;
}

.bannerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.banner h2 {
    font-size: 3rem;
    font-weight: bolder;
    margin: 1rem;
    color: blueviolet;
}

.banner p {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 5px;
}

.banner div {
    width: 50%;
    padding: 1rem;
}

.banner img {
    border-radius: 2rem;
}

/* css for mobile screen */
@media screen and (max-width: 950px) {
    .banner {
        margin: 2rem 1rem;
    }

    .banner h2 {
        font-size: 150%;
        font-weight: bolder;
    }

    .banner p {
        width: 80%;
        font-size: 100%;
        font-weight: 400;
    }


    .banner img {
        /* height: 80%; */
        width: 90%;
        /* display: none; */
    }

}

@media screen and (max-width: 750px) {
    .banner {
        margin: 1rem;
    }

    .banner h2 {
        font-size: 120%;
        margin: 0;
    }

    .banner p {
        font-size: 70%;
        font-weight: 300;
        margin: 5px;
    }
}